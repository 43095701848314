import React from 'react';
import slider from './Images/slider/a.png';
import slider2 from './Images/slider/b.png';
import slider3 from './Images/slider/c.png';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import './Css/style.css';

function Slider() {
    return (
        <div className="slider-main">
             <Carousel className="sliderimg">
                <div>
                    <img  className='sliderimg' src={slider} alt='image1' />
                </div>
                <div>
                    <img   className='sliderimg' src={slider2} alt='image1' />
                </div>
                <div>
                    <img   className='sliderimg' src={slider3} alt='image1'/>
                </div>
            </Carousel>
            </div>
    )
}

export default Slider
