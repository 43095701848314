import React,{useEffect} from 'react';
import Slider from './Slider.js';
import Product1 from './Product_type.js';
import Trading from './Trading.js';
import {Helmet} from "react-helmet";
import Contactbanner from './Contactbanner.js';

import './Css/style.css';

function Home() {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    return (
        <div>
        <Helmet>
            <title>Home | Aftab Chemical Co</title>
            <meta name="description" content="Aftab Chemicals former partner of Ahmad Chemical Co"/>
        </Helmet>
            <Slider/>
            <Product1/>
            <Trading/>
            <Contactbanner/>
        </div>
    )
}

export default Home
