import React,{useState,useEffect} from 'react';
import './Css/style.css';
import {Helmet} from "react-helmet";
import axios from 'axios';
import { Spinner,UncontrolledAlert,Alert,Button} from 'reactstrap';
// import Alert from 'react-bootstrap/Alert'
import MultiSelect from "react-multi-select-component";

function Contact() {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [company, setCompany] = useState('');
    const [city, setCity] = useState('');
    const [country, setCountry] = useState('');
    const [pii, setPii] = useState('');
    const [subject, setSubject] = useState('');
    const [website, setWebsite] = useState('');
    const [msg, setMsg] = useState();
    const [data, setData] = useState('');
    const [selected, setSelected] = useState([]);
    const [itemdata, setItemData] = useState();
    const [showLoader, setShowLoader] = useState(false);
    const [visible, setVisible] = useState(false);
    const [visible2, setVisible2] = useState(false);
    const [inquiry, setinquiry] = useState('');


    useEffect(() => {
        fetchData();
        window.scrollTo(0, 0);
    }, []);

    const fetchData = () => {
        axios
            .get('https://admin.aftabchm.com/api/allitems')
            .then((res) => {
                setItemData(res.data);
               
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const scrolling = () => {
        window.scrollTo(0, 0);
    ;}

    const emptyfeilds = () => {
        setName('');
        setEmail('');
        setPhone('');
        setWebsite('');
        setCompany('');
        setCity('');
        setMsg('');
        setCountry('');
        setSelected([]);
    };

    const options = [];
            itemdata && itemdata.forEach(function(item) {
                    var temp = {label:item.name, value:item.name}
                    options.push(temp);
                });

    function logIn(event) {
        setShowLoader(true);
        // msgtest();
        event.preventDefault();
        var count = 0;
        var items = '';
        selected.forEach(function(sel) {
            count++
            items+="</br></br>"+count+". "+sel.value+"\n"
        });
        var newmsg = "<p>";
        msg.split('\n').map((line, i) => (
            newmsg += line + "</br>"
        ));
        newmsg+="</p>";
        console.log(newmsg);
        //   console.log(items);
          
            const data = {
                name: name,
                email: email,
                phone: phone,
                company:company,
                product: items,
                city: city,
                country: country,
                message: newmsg,
                website:website,
                subject:"Null"
              }
                axios.post('https://admin.aftabchm.com/api/adduser', data)
                .then(res => { setData(res.data);
                    if(res.data.id != ""){
                        setinquiry(res.data.id);
                        scrolling();
                        setVisible(true);
                        emptyfeilds();
                        setShowLoader(false);
                        
                    }
                    else{
                        scrolling();
                        emptyfeilds();
                        setVisible2(true);
                        setShowLoader(false);
                    }
                                })
                        .catch(err => {
                                scrolling();
                                setVisible2(true);
                                console.log("something is wrong",err);
                                emptyfeilds();
                                setShowLoader(false);
                                });
           
      }
    return (
     <div class="bg-cover">
     <Helmet>
      <title>Contact | Aftab Chemical Co.</title>
      <meta name="description" content="Aftab Chemicals, former partner of Ahmad Chemical Co."/>
      </Helmet>
        <div class="container">
                    <div class="row">
                        <div class="col-md-2 col-sm-12">
                                <div class="producttitle title-base text-left">
                                    <hr />
                                    <h2 className="producth2">Contact</h2>
                                </div>
                        </div>
                    </div>
            </div> 
         <hr class="space s"/> 
         <div id="contactiddiv" class="section-empty section-item text-center">
            <div class="container content">
                <div class="row">
                    <div class="col-md-6">
                        {/* <Alert color="primary" isOpen={visible} toggle={onDismiss} fade={true}>
                            Your inquiry has been send successfully.<br></br><Alert.Link href="#">Inquiry no : AC{inquiry}</Alert.Link>
                        </Alert> */}
                        <Alert className="alertclass" color="success" isOpen={visible}>
                        Your inquiry has been send successfully.<br></br><a href="#" className="alert-link">Inquiry No : AC{inquiry}</a>.
                        </Alert>
                        <Alert className="alertclass" color="danger" isOpen={visible2}>
                        Something went wrong. Please try again
                        </Alert>
                        <div style={{display:'none'}}>
                            <ul>
                                {selected && selected.length && selected.map((items) => (
                                            <li key={items.index}>
                                                {items.value}
                                            </li>
                                        ))}
                        </ul>
                    </div>
                    <h2 className='tags'>Send a message</h2>
                    <form onSubmit={logIn} class="form-box form-ajax">
                        <div class="row">
                            <div class="col-md-6">
                                <p className="ptags">Name</p>
                                <input id="name" name="name" placeholder="" value={name} onChange={e => setName(e.target.value)} type="text" class="form-control form-value" required />
                            </div>
                            <div class="col-md-6">
                                <p className="ptags">Email</p>
                                <input id="email" name="email" placeholder="" value={email} onChange={e => setEmail(e.target.value)} type="email" class="form-control form-value" required/>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <p className="ptags">Phone</p>
                                <input id="phone" name="phone" placeholder="" type="tel" pattern="[0-9]{7,15}" value={phone} onChange={e => setPhone(e.target.value)} class="form-control form-value" required  />
                            </div>
                            <div class="col-md-6">
                                <p className="ptags">Company</p>
                                <input id="company" name="company" placeholder="" type="text" value={company} onChange={e => setCompany(e.target.value)} class="form-control form-value" required  />
                            </div>
                            <div class="col-md-6">
                                <p className="ptags">City</p>
                                <input id="city" name="city" placeholder="" type="text" value={city}  onChange={e => setCity(e.target.value)} class="form-control form-value" required  />
                            </div>
                            <div class="col-md-6">
                                <p className="ptags">Country</p>
                                <select class="form-select form-control form-value" value={country} onChange={e => setCountry(e.target.value)} id="country" name="country" >
                                    <option selected>Select Country</option>
                                    <option value="Afganistan">Afghanistan</option>
                                    <option value="Albania">Albania</option>
                                    <option value="Algeria">Algeria</option>
                                    <option value="American Samoa">American Samoa</option>
                                    <option value="Andorra">Andorra</option>
                                    <option value="Angola">Angola</option>
                                    <option value="Anguilla">Anguilla</option>
                                    <option value="Antigua & Barbuda">Antigua & Barbuda</option>
                                    <option value="Argentina">Argentina</option>
                                    <option value="Armenia">Armenia</option>
                                    <option value="Aruba">Aruba</option>
                                    <option value="Australia">Australia</option>
                                    <option value="Austria">Austria</option>
                                    <option value="Azerbaijan">Azerbaijan</option>
                                    <option value="Bahamas">Bahamas</option>
                                    <option value="Bahrain">Bahrain</option>
                                    <option value="Bangladesh">Bangladesh</option>
                                    <option value="Barbados">Barbados</option>
                                    <option value="Belarus">Belarus</option>
                                    <option value="Belgium">Belgium</option>
                                    <option value="Belize">Belize</option>
                                    <option value="Benin">Benin</option>
                                    <option value="Bermuda">Bermuda</option>
                                    <option value="Bhutan">Bhutan</option>
                                    <option value="Bolivia">Bolivia</option>
                                    <option value="Bonaire">Bonaire</option>
                                    <option value="Bosnia & Herzegovina">Bosnia & Herzegovina</option>
                                    <option value="Botswana">Botswana</option>
                                    <option value="Brazil">Brazil</option>
                                    <option value="British Indian Ocean Ter">British Indian Ocean Ter</option>
                                    <option value="Brunei">Brunei</option>
                                    <option value="Bulgaria">Bulgaria</option>
                                    <option value="Burkina Faso">Burkina Faso</option>
                                    <option value="Burundi">Burundi</option>
                                    <option value="Cambodia">Cambodia</option>
                                    <option value="Cameroon">Cameroon</option>
                                    <option value="Canada">Canada</option>
                                    <option value="Canary Islands">Canary Islands</option>
                                    <option value="Cape Verde">Cape Verde</option>
                                    <option value="Cayman Islands">Cayman Islands</option>
                                    <option value="Central African Republic">Central African Republic</option>
                                    <option value="Chad">Chad</option>
                                    <option value="Channel Islands">Channel Islands</option>
                                    <option value="Chile">Chile</option>
                                    <option value="China">China</option>
                                    <option value="Christmas Island">Christmas Island</option>
                                    <option value="Cocos Island">Cocos Island</option>
                                    <option value="Colombia">Colombia</option>
                                    <option value="Comoros">Comoros</option>
                                    <option value="Congo">Congo</option>
                                    <option value="Cook Islands">Cook Islands</option>
                                    <option value="Costa Rica">Costa Rica</option>
                                    <option value="Cote DIvoire">Cote DIvoire</option>
                                    <option value="Croatia">Croatia</option>
                                    <option value="Cuba">Cuba</option>
                                    <option value="Curaco">Curacao</option>
                                    <option value="Cyprus">Cyprus</option>
                                    <option value="Czech Republic">Czech Republic</option>
                                    <option value="Denmark">Denmark</option>
                                    <option value="Djibouti">Djibouti</option>
                                    <option value="Dominica">Dominica</option>
                                    <option value="Dominican Republic">Dominican Republic</option>
                                    <option value="East Timor">East Timor</option>
                                    <option value="Ecuador">Ecuador</option>
                                    <option value="Egypt">Egypt</option>
                                    <option value="El Salvador">El Salvador</option>
                                    <option value="Equatorial Guinea">Equatorial Guinea</option>
                                    <option value="Eritrea">Eritrea</option>
                                    <option value="Estonia">Estonia</option>
                                    <option value="Ethiopia">Ethiopia</option>
                                    <option value="Falkland Islands">Falkland Islands</option>
                                    <option value="Faroe Islands">Faroe Islands</option>
                                    <option value="Fiji">Fiji</option>
                                    <option value="Finland">Finland</option>
                                    <option value="France">France</option>
                                    <option value="French Guiana">French Guiana</option>
                                    <option value="French Polynesia">French Polynesia</option>
                                    <option value="French Southern Ter">French Southern Ter</option>
                                    <option value="Gabon">Gabon</option>
                                    <option value="Gambia">Gambia</option>
                                    <option value="Georgia">Georgia</option>
                                    <option value="Germany">Germany</option>
                                    <option value="Ghana">Ghana</option>
                                    <option value="Gibraltar">Gibraltar</option>
                                    <option value="Great Britain">Great Britain</option>
                                    <option value="Greece">Greece</option>
                                    <option value="Greenland">Greenland</option>
                                    <option value="Grenada">Grenada</option>
                                    <option value="Guadeloupe">Guadeloupe</option>
                                    <option value="Guam">Guam</option>
                                    <option value="Guatemala">Guatemala</option>
                                    <option value="Guinea">Guinea</option>
                                    <option value="Guyana">Guyana</option>
                                    <option value="Haiti">Haiti</option>
                                    <option value="Hawaii">Hawaii</option>
                                    <option value="Honduras">Honduras</option>
                                    <option value="Hong Kong">Hong Kong</option>
                                    <option value="Hungary">Hungary</option>
                                    <option value="Iceland">Iceland</option>
                                    <option value="Indonesia">Indonesia</option>
                                    <option value="India">India</option>
                                    <option value="Iran">Iran</option>
                                    <option value="Iraq">Iraq</option>
                                    <option value="Ireland">Ireland</option>
                                    <option value="Isle of Man">Isle of Man</option>
                                    <option value="Israel">Israel</option>
                                    <option value="Italy">Italy</option>
                                    <option value="Jamaica">Jamaica</option>
                                    <option value="Japan">Japan</option>
                                    <option value="Jordan">Jordan</option>
                                    <option value="Kazakhstan">Kazakhstan</option>
                                    <option value="Kenya">Kenya</option>
                                    <option value="Kiribati">Kiribati</option>
                                    <option value="Korea North">Korea North</option>
                                    <option value="Korea Sout">Korea South</option>
                                    <option value="Kuwait">Kuwait</option>
                                    <option value="Kyrgyzstan">Kyrgyzstan</option>
                                    <option value="Laos">Laos</option>
                                    <option value="Latvia">Latvia</option>
                                    <option value="Lebanon">Lebanon</option>
                                    <option value="Lesotho">Lesotho</option>
                                    <option value="Liberia">Liberia</option>
                                    <option value="Libya">Libya</option>
                                    <option value="Liechtenstein">Liechtenstein</option>
                                    <option value="Lithuania">Lithuania</option>
                                    <option value="Luxembourg">Luxembourg</option>
                                    <option value="Macau">Macau</option>
                                    <option value="Macedonia">Macedonia</option>
                                    <option value="Madagascar">Madagascar</option>
                                    <option value="Malaysia">Malaysia</option>
                                    <option value="Malawi">Malawi</option>
                                    <option value="Maldives">Maldives</option>
                                    <option value="Mali">Mali</option>
                                    <option value="Malta">Malta</option>
                                    <option value="Marshall Islands">Marshall Islands</option>
                                    <option value="Martinique">Martinique</option>
                                    <option value="Mauritania">Mauritania</option>
                                    <option value="Mauritius">Mauritius</option>
                                    <option value="Mayotte">Mayotte</option>
                                    <option value="Mexico">Mexico</option>
                                    <option value="Midway Islands">Midway Islands</option>
                                    <option value="Moldova">Moldova</option>
                                    <option value="Monaco">Monaco</option>
                                    <option value="Mongolia">Mongolia</option>
                                    <option value="Montserrat">Montserrat</option>
                                    <option value="Morocco">Morocco</option>
                                    <option value="Mozambique">Mozambique</option>
                                    <option value="Myanmar">Myanmar</option>
                                    <option value="Nambia">Nambia</option>
                                    <option value="Nauru">Nauru</option>
                                    <option value="Nepal">Nepal</option>
                                    <option value="Netherland Antilles">Netherland Antilles</option>
                                    <option value="Netherlands">Netherlands (Holland, Europe)</option>
                                    <option value="Nevis">Nevis</option>
                                    <option value="New Caledonia">New Caledonia</option>
                                    <option value="New Zealand">New Zealand</option>
                                    <option value="Nicaragua">Nicaragua</option>
                                    <option value="Niger">Niger</option>
                                    <option value="Nigeria">Nigeria</option>
                                    <option value="Niue">Niue</option>
                                    <option value="Norfolk Island">Norfolk Island</option>
                                    <option value="Norway">Norway</option>
                                    <option value="Oman">Oman</option>
                                    <option value="Pakistan">Pakistan</option>
                                    <option value="Palau Island">Palau Island</option>
                                    <option value="Palestine">Palestine</option>
                                    <option value="Panama">Panama</option>
                                    <option value="Papua New Guinea">Papua New Guinea</option>
                                    <option value="Paraguay">Paraguay</option>
                                    <option value="Peru">Peru</option>
                                    <option value="Phillipines">Philippines</option>
                                    <option value="Pitcairn Island">Pitcairn Island</option>
                                    <option value="Poland">Poland</option>
                                    <option value="Portugal">Portugal</option>
                                    <option value="Puerto Rico">Puerto Rico</option>
                                    <option value="Qatar">Qatar</option>
                                    <option value="Republic of Montenegro">Republic of Montenegro</option>
                                    <option value="Republic of Serbia">Republic of Serbia</option>
                                    <option value="Reunion">Reunion</option>
                                    <option value="Romania">Romania</option>
                                    <option value="Russia">Russia</option>
                                    <option value="Rwanda">Rwanda</option>
                                    <option value="St Barthelemy">St Barthelemy</option>
                                    <option value="St Eustatius">St Eustatius</option>
                                    <option value="St Helena">St Helena</option>
                                    <option value="St Kitts-Nevis">St Kitts-Nevis</option>
                                    <option value="St Lucia">St Lucia</option>
                                    <option value="St Maarten">St Maarten</option>
                                    <option value="St Pierre & Miquelon">St Pierre & Miquelon</option>
                                    <option value="St Vincent & Grenadines">St Vincent & Grenadines</option>
                                    <option value="Saipan">Saipan</option>
                                    <option value="Samoa">Samoa</option>
                                    <option value="Samoa American">Samoa American</option>
                                    <option value="San Marino">San Marino</option>
                                    <option value="Sao Tome & Principe">Sao Tome & Principe</option>
                                    <option value="Saudi Arabia">Saudi Arabia</option>
                                    <option value="Senegal">Senegal</option>
                                    <option value="Seychelles">Seychelles</option>
                                    <option value="Sierra Leone">Sierra Leone</option>
                                    <option value="Singapore">Singapore</option>
                                    <option value="Slovakia">Slovakia</option>
                                    <option value="Slovenia">Slovenia</option>
                                    <option value="Solomon Islands">Solomon Islands</option>
                                    <option value="Somalia">Somalia</option>
                                    <option value="South Africa">South Africa</option>
                                    <option value="Spain">Spain</option>
                                    <option value="Sri Lanka">Sri Lanka</option>
                                    <option value="Sudan">Sudan</option>
                                    <option value="Suriname">Suriname</option>
                                    <option value="Swaziland">Swaziland</option>
                                    <option value="Sweden">Sweden</option>
                                    <option value="Switzerland">Switzerland</option>
                                    <option value="Syria">Syria</option>
                                    <option value="Tahiti">Tahiti</option>
                                    <option value="Taiwan">Taiwan</option>
                                    <option value="Tajikistan">Tajikistan</option>
                                    <option value="Tanzania">Tanzania</option>
                                    <option value="Thailand">Thailand</option>
                                    <option value="Togo">Togo</option>
                                    <option value="Tokelau">Tokelau</option>
                                    <option value="Tonga">Tonga</option>
                                    <option value="Trinidad & Tobago">Trinidad & Tobago</option>
                                    <option value="Tunisia">Tunisia</option>
                                    <option value="Turkey">Turkey</option>
                                    <option value="Turkmenistan">Turkmenistan</option>
                                    <option value="Turks & Caicos Is">Turks & Caicos Is</option>
                                    <option value="Tuvalu">Tuvalu</option>
                                    <option value="Uganda">Uganda</option>
                                    <option value="United Kingdom">United Kingdom</option>
                                    <option value="Ukraine">Ukraine</option>
                                    <option value="United Arab Erimates">United Arab Emirates</option>
                                    <option value="United States of America">United States of America</option>
                                    <option value="Uraguay">Uruguay</option>
                                    <option value="Uzbekistan">Uzbekistan</option>
                                    <option value="Vanuatu">Vanuatu</option>
                                    <option value="Vatican City State">Vatican City State</option>
                                    <option value="Venezuela">Venezuela</option>
                                    <option value="Vietnam">Vietnam</option>
                                    <option value="Virgin Islands (Brit)">Virgin Islands (Brit)</option>
                                    <option value="Virgin Islands (USA)">Virgin Islands (USA)</option>
                                    <option value="Wake Island">Wake Island</option>
                                    <option value="Wallis & Futana Is">Wallis & Futana Is</option>
                                    <option value="Yemen">Yemen</option>
                                    <option value="Zaire">Zaire</option>
                                    <option value="Zambia">Zambia</option>
                                    <option value="Zimbabwe">Zimbabwe</option>
                                </select>
                            </div>
                            <div class="col-md-6">
                                <p className="ptags">Product Interested In</p>
                                <MultiSelect
                                    options={options}
                                    value={selected}
                                    onChange={setSelected}
                                    labelledBy="Select"
                                    className="multiselect pb-3"
                                    hasSelectAll={false}
                                />
                                {/* <input id="pii" name="pii" placeholder="" type="text" value={pii} onChange={e => setPii(e.target.value)} class="form-control form-value" required  /> */}
                            </div>
                            <div class="col-md-6">
                                <p className="ptags">Website</p>
                                <input id="website" name="website" placeholder="" type="url" value={website} onChange={e => setWebsite(e.target.value)} class="form-control form-value"  required />
                            </div>
                        </div>
                        {/* <div id="fordisplayhide" class="row">
                        <div  class="col-md-12">
                                <p className="ptags">Subject</p>
                                <input id="subject" name="subject" placeholder="" type="text" value="Null" onChange={e => setSubject(e.target.value)} class="form-control form-value" required/>
                            </div>
                        </div> */}
                      
                        <div class="row">
                            <div class="col-md-12">
                                <p className="ptags">Message</p>
                                <textarea  id="messagge" name="messagge" value={msg} onChange={e => setMsg(e.target.value)} class="h-75 form-control form-value" required  ></textarea>
                                {showLoader ? 
                                <Button color="primary" type="submit" id="custom-btn" disabled><i class="fa fa-mail-reply-all"></i><Spinner size="sm" type="grow" color="dark" /></Button> 
                                :
                                <Button color="primary" type="submit" id="custom-btn" ><i class="fa fa-mail-reply-all"></i>Send Message</Button>
                                }
                                
                                {/* <Spinner size="sm" color="primary" /> */}
                            </div>
                        </div>
                        {/* <div class="success-box">
                            <div class="alert alert-success">Congratulations. Your message has been sent successfully</div>
                        </div>
                        <div class="error-box">
                            <div class="alert alert-warning">Error, please retry. Your message has not been sent</div>
                        </div> */}
                    </form>
                </div>
                <div class="col-md-6">
                    <h2 className='tagss'>How to reach us</h2>
                    <p class="text-color no-margins">You can reach by following the directions</p>
                   
                    <div class="row">
                     
                        <div class="col-md-6">
                            <ul class="fa-ul">
                                <li>
                                    <i class="clr fa-li fa fa-home"></i>
                                    1st Floor, Ahmad Arcade
                                    Daryalal Street, Jodia Bazar
                                    Karachi, Pakistan.
                                </li>
                               
                            </ul>
                        </div>
                        <div class="col-md-6">
                            <ul class="fa-ul">
                                <li><i class="clr fa-li fa fa-phone"></i>(+92) 21 3246 3385 / 86 / 87 / 88</li>
                                <li><i class="clrr fas fa-envelope"></i>contact@aftabchm.com</li>
                            </ul>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
    </div>
    )}

export default Contact
