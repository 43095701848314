import React from 'react';
import './App.css';
import Navv from './components/Navv';
import Footerr from './components/Footerr';
import home from './components/Home';
import Product from './components/Product';
import Contact from './components/Contact';
import About from './components/About';
import Details from './components/details';
import {Helmet} from "react-helmet";
import {BrowserRouter as Router,Switch,Route} from 'react-router-dom';


function App() {
  return (
    <Router>
    <div>
      <Navv/>
      <Helmet>
      <title>Aftab Chemical Co.</title>
      <meta name="description" content="Aftab Chemicals, former partner of Ahmad Chemical Co"/>
      </Helmet>
      <Switch>
      <Route path="/" exact component={home} />
      <Route path="/Product" component={Product} />
      <Route path="/Contact" component={Contact} />
      <Route path="/About" component={About} />
      <Route path="/Details" component={Details} />
      </Switch>
      <Footerr/>
    </div>
    </Router>
  );
}

export default App;
