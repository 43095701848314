import React,{useEffect} from 'react';
import { Container,Row,Col } from 'reactstrap';
import {Helmet} from "react-helmet";


function About() {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    return (
        <div className="bg-cover">
        <Helmet>
            <title>About | Aftab Chemical Co.</title>
            <meta name="description" content="Royal Chemicals is a Sister-Concern company of <b>Aftab Chemical Co."/>
        </Helmet>
        <div class="container">
                    <div class="row">
                        <div class="col-md-2 col-sm-12">
                                <div class="producttitle title-base text-left">
                                    <hr />
                                    <h2 className="producth2">About</h2>
                                </div>
                        </div>
                    </div>
            </div> 
         <hr class="space s"/> 
         <div id="contactiddiv" class="section-empty section-item text-center">
         <Container>
         <Row>
        <Col md="12">
        <div className="about-heading">
        <p><b>Aftab Chemical Co.</b> is Major Distributor of Food, Pharmaceutical Inactive Chemicals and many other
                general chemical raw material to all over Pakistan.<br></br><br></br> 
                Our goal is to provide the Best Quality Material to leading Pharmaceutical and Food Manufacturers
                as well as SMEs with competitive prices in Pakistan for their entire satisfaction of quality, quantity,
                variety and smooth distribution of chemicals.<br></br><br></br>
                <b>Royal Chemicals</b> is a Sister-Concern company of <b>Aftab Chemical Co.</b></p>
        </div>
        </Col>
        
      </Row>
                </Container> 
                </div>
        </div>
    );
};

export default About
