import React, { useState,useEffect } from 'react';
import './Css/style.css';
import logo from './Images/logo.png';
import {Link} from 'react-router-dom';
import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink,
    Col
  } from 'reactstrap';


const Navv = (props) => {
    const [isOpen, setIsOpen] = useState(false);
  
    const toggle = () => setIsOpen(!isOpen);
   
    const [windowSize, setWindowSize] = useState('');
    function checkwidth(){
      if(windowSize <= "767"){
        return toggle()
      }
      
    };
    
    useEffect(() => {
      // Handler to call on window resize
      function handleResize() {
        // Set window width/height to state
        setWindowSize(window.innerWidth);
      }
      // Add event listener
      window.addEventListener("resize", handleResize);
      // Call handler right away so state gets updated with initial window size
      handleResize();
      // Remove event listener on cleanup
      return () => window.removeEventListener("resize", handleResize);
    }, []); // Empty array ensures that effect is only run on mount
    return (
      <div className='navfix'>
      <div class="d-none d-xl-block">
            <div className='upperheader'>
            <Col sm="12" md={{ size:11,offset: 0}}>
            <div className='upperitems'>
            <a className='atags' target="_blank" rel='noreferrer' href="https://wa.me/922132463385"><i class="fas fa-phone-alt"></i>  &nbsp;(+92) 21 3246 3383 /84 /85 /86 /87 /88</a> &nbsp;<span className='hrtag'>|</span>&nbsp;      
            <a className='atags' target="_blank" rel='noreferrer' href="mailto:contact@aftabchm.com"><i class="fas fa-envelope"></i> &nbsp;contact@aftabchm.com</a>
            &nbsp;<span className='hrtag'>|</span>&nbsp;  
             <a className='atags'target="_blank" rel='noreferrer' href="https://g.page/aftab-chemical-co?gm"><span> <i class="fas fa-map-marker-alt"> &nbsp;</i>1st Floor, Ahmad Arcade, Daryalal Street, Jodia Bazar, Karachi.</span></a>
             </div>
             </Col>
            </div>
        </div>
        <Navbar className='' light expand="md">
        <div className='logo'>
          <NavbarBrand href="/"><img className='aftablogo' alt='aftabchmlogo' src={logo} /></NavbarBrand>
          </div>
          <NavbarToggler onClick={toggle} />
          <Collapse isOpen={isOpen} navbar>
            <Nav onClick={checkwidth} className="mr-auto" navbar>
              <NavItem >
                <NavLink className='navitem'><Link className="navlink"  to='/'> Home</Link></NavLink> 
              </NavItem>
              <NavItem>
                <NavLink className='navitem'><Link className="navlink"  to='/product'>Product </Link></NavLink>
              </NavItem>
              <NavItem>
                <NavLink className='navitem'><Link className="navlink"  to='/about'>About</Link></NavLink> 
              </NavItem>
              <NavItem>
                <NavLink className='navitem'><Link className="navlink"  to='/contact'>Contact</Link></NavLink> 
              </NavItem>
              
            </Nav>
          </Collapse>
        </Navbar>
        <div className="line">
        </div>
        
      </div>
    );
  }

export default Navv;