import React,{useEffect,useState} from 'react';
import {Helmet} from "react-helmet";
import { Col, Row, Button, Form, FormGroup, Label, Input } from 'reactstrap';
import axios from 'axios';
import './Css/style.css';

function Product() {
    const [data, setData] = useState("");
    const [searchitems, setSearchitems] = useState('');
    const [latter, setNewLatter] = useState('');
    const checkvbl = latter;

    console.log(checkvbl);
    
    // const [loading, setloading] = useState(true);
   


    const fetchData = () => {
        axios
            .get('https://admin.aftabchm.com/api/allitems')
            .then((res) => {
                setData(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
    };
    useEffect(() => {
        fetchData();
        window.scrollTo(0, 0);
    }, []);
    return (
    <div class="bg-cover">
        <Helmet>
            <title>Products | Aftab Chemical Co.</title>
            <meta name="description" content="Item list | Aftab Chemicals, former partner of Ahmad Chemical Co."/>
        </Helmet>
            <div class="container">
                    <div class="row">
                        <div class="col-md-2 col-sm-12">
                                <div class="producttitle title-base text-left">
                                <p className="productp">List of items we deal in.</p>
                                    <hr />
                                    <h2 className="producth2">Proucts</h2>
                                </div>
                        </div>
                    </div>
            </div>   
         <hr class="space s"/>    
            <div id="forpadding" class="section-empty section-item text-center">
                <div class="container content">
                    <h3 class="ti-heading">All Items</h3>
                    <div class="griddiv col-md-3">
                            <div className="psearch">
                                <p className="product-ptag">Search</p>
                                        <input 
                                        class="searchfeild form-control"
                                        type="search"
                                        name="search"
                                        id="exampleSearch"
                                        placeholder=""
                                        onChange={(event) => {
                                            setSearchitems(event.target.value);
                                        }}
                                        />
                            </div>  
                            <div class="chek">
                                    <p className="product-ptag">Sort by</p>
                                        <select value={latter} onChange={e => setNewLatter(e.target.value)} class="searchfeild form-select form-control form-value" id="country" name="country" >
                                            <option value="" selected>All</option>
                                            <option value="a">A</option>
                                            <option value="b">B</option>
                                            <option value="c">C</option>
                                            <option value="d">D</option>
                                            <option value="e">E</option>
                                            <option value="f">F</option>
                                            <option value="g">G</option>
                                            <option value="h">H</option>
                                            <option value="i">I</option>
                                            <option value="j">J</option>
                                            <option value="k">K</option>
                                            <option value="l">L</option>
                                            <option value="m">M</option>
                                            <option value="n">N</option>
                                            <option value="o">O</option>
                                            <option value="p">P</option>
                                            <option value="q">Q</option>
                                            <option value="r">R</option>
                                            <option value="s">S</option>
                                            <option value="t">T</option>
                                            <option value="u">U</option>
                                            <option value="v">V</option>
                                            <option value="w">W</option>
                                            <option value="x">X</option>
                                            <option value="y">Y</option>
                                            <option value="z">Z</option>
                                            
                                        </select>
                        </div>
                        </div>
                        
                        
                    <div class="row">
                    {data && data.length && data.filter((items) => {
                        if(latter == "") {
                            if(searchitems == ""){
                            return items
                        }
                        else if (items.name.toLowerCase().includes(searchitems.toLocaleLowerCase())){
                            return items
                        }
                        }
                        else if (items.name[0].toLowerCase() === `${checkvbl}`){
                            return items
                        }
                        
                    }).map((items) => (
                        <div class="col-md-6">
                            <div key={items.index} class="list-items text-left">
                                <div class="list-item">
                                <div class="row">
                                    <div class="col-md-9 col-xs-12">
                                    <h3>{items.name}</h3>
                                    <hr class="space s" /> 
                                    </div>     
                                </div>
                                </div>
                            </div>
                          </div>
                          ))}
                    </div> 
                </div>
            </div>
    </div>
)}

export default Product
