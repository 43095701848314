import React from 'react';
import slider2 from './Images/slider/Sd-3.png';
import InfiniteCarousel from 'react-leaf-carousel';

function Details() {
  

    return (
        <div id="banner-d-color" className="maindiv">
          <div class="container">
                <div class="row">
                    <div class="col-md-2 col-sm-12">
                            <div class="producttitle title-base text-left">
                            <p className="productp"></p>
                                <hr />
                                <h2 className="producth2">Details</h2>
                            </div>
                    </div>
                </div>
         </div>   
         <hr class="space s"/> 
         <div class="section-empty section-item">
        <div class="container content">
        <InfiniteCarousel
    breakpoints={[
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
    ]}
    dots={true}
    showSides={true}
    sidesOpacity={.5}
    sideSize={.1}
    slidesToScroll={4}
    slidesToShow={4}
    scrollOnDevice={true}
  >
    <div>
      <img
        alt=''
        src={slider2}
      />
    </div>
    <div>
      <img
        alt=''
        src={slider2}
      />
    </div>
    <div>
      <img
        alt=''
        src={slider2}
      />
    </div>
    <div>
      <img
        alt=''
        src={slider2}
      />
    </div>
    <div>
      <img
        alt=''
        src={slider2}
      />
    </div>
    <div>
      <img
        alt=''
        src={slider2}
      />
    </div>
    <div>
      <img
        alt=''
        src={slider2}
      />
    </div>
    <div>
      <img
        alt=''
        src={slider2}
      />
    </div>
    <div>
      <img
        alt=''
        src={slider2}
      />
    </div>
    <div>
      <img
        alt=''
        src={slider2}
      />
    </div>
    <div>
      <img
        alt=''
        src={slider2}
      />
    </div>
    <div>
      <img
        alt=''
        src={slider2}
      />
    </div>
    <div>
      <img
        alt=''
        src={slider2}
      />
    </div>
    <div>
      <img
        alt=''
        src={slider2}
      />
    </div>
    <div>
      <img
        alt=''
        src={slider2}
      />
    </div>
    <div>
      <img
        alt=''
        src={slider2}
      />
    </div>
    <div>
      <img
        alt=''
        src={slider2}
      />
    </div>
    <div>
      <img
        alt=''
        src={slider2}
      />
    </div>
    <div>
      <img
        alt=''
        src={slider2}
      />
    </div>
    <div>
      <img
        alt=''
        src={slider2}
      />
    </div>
  </InfiniteCarousel>
            
            <hr class="space" />
            <div class="row">
                <div class="col-md-9">
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                        aliquip ex ea commodo consequat duis aute irure dolorquis nostrud exercitation ullamco laboris   hendrerit incidunt pulvinar litora eleisto.
                    </p>
                    <p class="block-quote quote-1">
                        It is better to lead from behind and to put others in front,
                        especially when you celebrate victory when nice things occur.

                    </p>
                    <hr class="space m" />
                    <div class="btn-group social-group btn-group-icons  ">
                        <a target="_blank" href="#"><i class="fa fa-facebook"></i></a>
                        <a target="_blank" href="#"><i class="fa fa-twitter"></i></a>
                        <a target="_blank" href="#"><i class="fa fa-instagram"></i></a>
                        <a target="_blank" href="#"><i class="fa fa-youtube"></i></a>
                    </div>
                </div>
                <div class="col-md-3">
                    <ul class="list-texts">
                        <li><b>Client.</b>   Awesome Company</li>
                        <li><b>Location.</b>   Mountain View CA 94043</li>
                        <li><b>Surface Area.</b> 450,000 m2</li>
                        <li><b>Year.</b>   2014</li>
                        <li><b>Value.</b> $250.000</li>
                        <li><b>Architect.</b>   Jason & Perry</li>
                    </ul>
                    <hr class="space s" />
                    <a href="#" class="anima-button btn btn-border btn-sm"><i class="fa fa-paper-plane-o"></i>Visit website</a>
                </div>
            </div>
            <hr class="space m" />
            <hr class="d" />
              <hr class="space m" />
            <div class="row porfolio-bar">
                <div class="col-md-2">
                    <div class="icon-box" data-anima="scale-up" data-trigger="hover">
                        <i class="fa fa-arrow-left text-m"></i><label class="text-s"><a href="#">Previous</a></label>
                    </div>
                </div>
                <div class="col-md-8 text-center">
                    <a href="#" data-anima="scale-up" data-trigger="hover"><i class="fa fa-th anima"></i></a>
                </div>
                <div class="col-md-2 text-right">
                    <div class="icon-box icon-box-right" data-anima="scale-up" data-trigger="hover">
                        <label class="text-s"><a href="#">Next</a></label><i class="fa fa-arrow-right text-m"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
        </div>
    );
};

export default Details
