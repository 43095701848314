import React,{useEffect,useState} from 'react';
import './Css/style.css';
import logo from './Images/logo.png';
import {Link} from 'react-router-dom';

function Footerr() {

    const [date , setDate] = useState();

    const getYear = () =>  setDate(new Date().getFullYear())


    useEffect(() => {
        getYear();
    }, [])
    return (

<footer class="footer-base">
<hr class="space m" />
    <div class="container">
                <div class="row">
                    <div class="col-md-6 footer-center text-left">
                        <img width="140" className="footerlogo" src={logo} alt="Aftab Chemical Co." />
                        
                        <a target="_blank" rel="noreferrer" className="atag" href="https://g.page/aftab-chemical-co?gm">
                            <p class="atag text-s">1st Floor, Ahmad Arcade, Daryalal Street, Jodia Bazar, Karachi, Pakistan.</p>
                        </a>
                        <div className="atag tag-row text-s">
                            <span>contact@aftabchm.com</span>
                        </div>
                        <div class="tag-row text-s">
                            <a target="_blank"  rel="noreferrer" className="atag" href="https://wa.me/922132463385" ><span>(+92) 21 3246 3383 /84 /85 /86 /87 /88</span></a><br/>
                        </div>
                    </div>
                    <div class="col-md-6 footer-left text-left">
                        <div class="row">
                            <div class="col-md-6 text-s">
                                <h3 className="footerh3">Menu</h3>
                                <Link className="atag"  to='/'> Home</Link><br/>
                                <Link className="atag"  to='/Product'>Product </Link><br/>
                                <Link className="atag"  to='/Contact'>Contact</Link><br/>
                                {/* <a className="atag" href="index">Home</a><br />
                                <a className="atag" href="contact">Contacts</a><br />
                                <a className="atag" href="products">Products</a><br /> */}
                            </div>
                            <div class="col-md-6 text-s">
                                <h3 className="footerh3">Products</h3>
                                <a className="atag" href="#">Food</a><br />
                                <a className="atag" href="#">Pharma</a><br />
                                <a className="atag" href="#">General</a><br />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <hr className="space m" />
            <div className="copy-row">
                <div className="atag col-md-12 copy-text">
                    © {date} Aftab Chemical Co. | Developed by <a className="atag" href="https://chaynztech.com">Chaynz Tech</a>
                </div>
            </div>
    </footer>
    )}

export default Footerr;
