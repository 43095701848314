import React from 'react';
import './Css/style.css';

export default function Contactbanner() {
    return (
        <div>
            <div class="section-empty">
        <div class="container content">
            <div class="row vertical-row">
                <div class="col-md-4 col-sm-12">
                    <div class="title-base text-left">
                        <hr />
                        <h2 className="h2tags">Contact us now</h2>
                        <p>Get in touch</p>
                    </div>
                </div>
                <div className="col-md-8 custompadding boxed white">
                    <div className="row">
                        <div class="col-md-6">
                            <ul class="fa-ul text-light">
                                <li><a target="_blank" rel="noreferrer" className="aatags" href="https://g.page/aftab-chemical-co?gm"><i class="fa-li fa fa-map-marker"></i> 1st Floor, Ahmad Arcade, Daryalal Street, Jodia Bazar, Karachi, Pakistan.</a></li>
                                <li><i class="fa-li fa fa-calendar "></i> Mon - Sat </li>
                            </ul>
                        </div>
                        <div class="col-md-6">
                            <ul class="fa-ul text-light">
                            
                                <li><i className="abc far fa-envelope"></i>Contact@aftabchm.com</li>
                                <li><a target="_blank" rel="noreferrer" className="aatags" href="https://wa.me/922132463385"><i className="fa-li fa fa-phone "></i>(+92) 21 3246 3383 /84 /85 /86 /87 /88</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
        </div>
    )}
