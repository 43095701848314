import React,{useEffect,useState} from 'react';
import axios from 'axios';


function Trading() {
    const [data, setData] = useState("");
 

    const fetchData = () => {
        axios
            .get('https://admin.aftabchm.com/api/itemsbytrending')
            .then((res) => {
                setData(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    useEffect(() => {
        fetchData();
    }, []);
    return (
        <div>
            <div  class="section-empty section-item text-center">
        <div class="container content">
            <h3 className="ti-heading">Trending Items</h3>
            <hr class="space m" />
            <div class="row">
                    {data && data.length && data.map((items) => (
                        <div class="col-md-6">
                            <div key={items.index} class="list-items text-left">
                                <div class="list-item">
                                <div class="row">
                                    <div class="col-md-9 col-xs-12">
                                    <h3>{items.name}</h3>
                                    <hr class="space s" /> 
                                    </div>     
                                </div>
                                </div>
                            </div>
                          </div>
                          ))}
                    </div> 
                
        </div>
    </div>
        </div>
    )
}

export default Trading
