import React from 'react';
import './Css/style.css';

function Product_type() {
    return (
        <div class="mt-5 container content">
            <h3 class="text-center">Product Type</h3>
            <div class="grid-list">
                <div class="grid-box  row" data-lightbox-anima="show-scale">
                    <div class="grid-item  col-md-4">
                        <div class="advs-box advs-box-top-icon boxed white" data-anima="rotate-20" data-trigger="hover">
                        <i class="icon circle anima fas fa-glass-martini"></i>
                            <h3>Food</h3>
                        </div>
                    </div>
                    <div class="grid-item  col-md-4">
                        <div class="advs-box advs-box-top-icon boxed white" data-anima="rotate-20" data-trigger="hover">
                        <i class="icon circle anima fas fa-calendar-plus"></i>
                            <h3>Pharma</h3>
                        </div>
                    </div>
                    <div class="grid-item col-md-4">
                        <div class="advs-box advs-box-top-icon boxed white" data-anima="rotate-20" data-trigger="hover">
                        <i class="icon circle anima fas fa-bars"></i>
                            <h3>General</h3>
                        </div>
                    </div>
                   
                </div>
            </div>
        </div>
    )
}

export default Product_type
